import React, { useEffect } from "react";
import { useState } from "react";
import "../App.css";
import {
  Card,
  Typography,
  Form,
  Col,
  Button,
  notification,
  Row,
  Divider,
  Radio,
  // Upload,
  Checkbox,
  ConfigProvider,
  Alert,
} from "antd";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import icon from "../assets/dory-icon.png";
import phil from "../philippine-location-json-for-geer";
import renderInput from "../components/utilities/inputForms";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../helper/axios";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import PackageCard from "../components/card";

const { Title } = Typography;

const getUniqueOptions = (options, key) => {
  const seen = new Set();
  return options.filter((item) => {
    const keyValue = item[key];
    return seen.has(keyValue) ? false : seen.add(keyValue);
  });
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const options = [
  {
    id: 1,
    label: "BASIC",
    value: { price: "650", packageName: "BASIC" },
    about: "1x Dory long sleeve",
    imageUrl:
      "https://groceria-storage.sgp1.cdn.digitaloceanspaces.com/assets/images/image-packages/PACKAGE%20A.png",
  },
  {
    id: 2,
    label: "PLUS",
    value: { price: "1,000", packageName: "PLUS" },
    about: "1x Thermal bag",

    imageUrl:
      "https://groceria-storage.sgp1.cdn.digitaloceanspaces.com/assets/images/image-packages/PACKAGE%20C.png",
  },
  {
    id: 3,
    label: "PRO",
    value: { price: "1,500", packageName: "PRO" },
    about: "1x Dory long sleeve,1x Thermal bag ",
    imageUrl:
      "https://groceria-storage.sgp1.cdn.digitaloceanspaces.com/assets/images/image-packages/PACKAGE%20B.png",
  },
];

function RiderSignUp() {
  const query = useQuery();
  const navigate = useNavigate();
  const referralCode = query.get("ref");

  const [cities, setCities] = useState([]);
  const [barangays, setBarangays] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [radioValue, setRadioValue] = useState(null);
  // const [imageFileLogo, setImageFileLogo] = useState(null);
  // const [imageUrlLogo, setImageUrlLogo] = useState("");
  const [isCityRiderMaxed, setIsCityRiderMaxed] = useState(false);

  const [serviceCities, setServiceCities] = useState([]);
  // const [serviceProvinces, setServiceProvinces] = useState([]);
  const [acceptedTermsAndCondition, setAcceptedTermsAndCondition] =
    useState(false);

  // const [selectedSize, setSelectedSize] = useState("");
  // const [selectedPackage, setSelectedPackage] = useState(null); // for button flaging

  const uniqueOptions = getUniqueOptions(phil.provinces, "prov_code");

  // const handlePackageSelect = (packageName) => {
  //   setSelectedPackage(packageName);
  // };

  // const handleSizeChange = (e) => {
  //   setSelectedSize(e.target.value);
  // };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  useEffect(() => {
    if (!referralCode) {
      navigate("/404");
    }
  }, [referralCode, navigate]);

  const schema = yup.object().shape({
    first_name: yup.string().required("Name is required field"),
    last_name: yup.string().required("Name is required field"),
    username: yup.string().required("username is required field"),
    contact_number: yup
      .string()
      .required("Contact Number is required field")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      )
      .min(11, "too short")
      .max(11, "too long"),
    province: yup.string().required(),
    city: yup.string().required(),
    barangay: yup.string().required(),
    service_city: yup.string().required("Preferred City is required"),
    service_province: yup.string().required("Preferred Province is required"),
    password: yup.string().required("Password is required field"),
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Email is required"),
    dob: yup
      .date()
      .required("Date of Birth is a required field")
      .max(new Date(), "Date of Birth cannot be in the future"),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: "",
      last_name: "",
      province: "",
      city: "",
      barangay: "",
      contact_number: "",
      password: "",
      dob: "",
      username: "",
      service_city: "",
      service_province: "",
      email: "",
    },
  });

  const onError = (errors) => {
    notification.error({
      message: "Form Submission Error",
      description: "Please check the form for errors and try again.",
    });
    console.log("Form Errors:", errors);
  };

  // const fetchServiceableProvinces = async () => {
  //   try {
  //     const { data } = await axiosInstance().get(
  //       "/api/rider/serviceable-province-list"
  //     );

  //     if (data) {
  //       setServiceProvinces(data.serviceable_provinces);
  //     }
  //   } catch (error) {
  //     console.log(">>> error fetching serviceable Provinces");
  //   }
  // };

  // useEffect(() => {
  //   fetchServiceableProvinces();
  // }, []);

  // const fetchServiceableCities = async (province) => {
  //   try {
  //     const { data } = await axiosInstance().get(
  //       `/api/rider/serviceable-cities-list/${province}`
  //     );

  //     setServiceCities(data.serviceable_cities);
  //   } catch (error) {
  //     console.log(">>> error fetching serviceable Cities");
  //   }
  // };

  const onCheck = (e) => {
    setAcceptedTermsAndCondition(!acceptedTermsAndCondition);
  };

  const onChangeServiceProvince = async (prov_code) => {
    // change me
    setValue("service_city", "");

    setServiceCities(phil.getCityMunByProvince(prov_code));
    // fetchServiceableCities(prov_code);
  };

  const onChangeServiceCities = async () => {
    const service_city = watch("service_city");
    const service_province = watch("service_province");

    // test
    const realServiceProvince = phil.provinces.filter(
      (province) => province.prov_code === service_province
    )[0];
    const realServiceCity = phil.city_mun.filter(
      (city) => city.mun_code === service_city
    )[0].name;
    // test

    const response = await axiosInstance().get(
      `/api/rider/verify-city-limit?city=${realServiceCity}&province=${realServiceProvince.name}`
    );

    setIsCityRiderMaxed(response.data.isCityRiderMax);
  };

  const onChangeProvince = (prov_code) => {
    setValue("city", "");
    setValue("barangay", "");
    setCities(phil.getCityMunByProvince(prov_code));
    setBarangays([]);
  };

  const onChangeCity = (mun_code) => {
    setValue("barangay", "");
    setBarangays(phil.getBarangayByMun(mun_code));
  };

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      // if (radioValue === null) {
      //   openNotification("ERROR", "Please select one package");
      //   setLoading(false);
      //   return;
      // }

      // if (radioValue.packageName !== "PLUS" && selectedSize === "") {
      //   openNotification("ERROR", "Please select sleeve size");
      //   setLoading(false);
      //   return;
      // }

      // console.log(">>>> imageFileLogo:", imageFileLogo);

      // if (imageFileLogo === null) {
      //   openNotification("ERROR", "Logo is required");
      //   setLoading(false);
      //   return;
      // }

      const province = phil.provinces.filter(
        (province) => province.prov_code === values.province
      )[0];
      const city = phil.city_mun.filter(
        (city) => city.mun_code === values.city
      )[0].name;
      const barangay = phil.barangays.filter(
        (barangay) => barangay.brgy_code === values.barangay
      )[0].name;

      const serviceCity = phil.city_mun.filter(
        (city) => city.mun_code === values.service_city
      )[0].name;

      const serviceProvince = phil.provinces.filter(
        (province) => province.prov_code === values.service_province
      )[0];

      // const a = {
      //   first_name: values.first_name,
      //   last_name: values.last_name,
      //   date_of_birth: values.dob,
      //   contact_number: values.contact_number,
      //   email: values.email,
      //   user_name: values.username,
      //   user_password: values.password,
      //   province: province.name,
      //   city: city,
      //   barangay,
      //   address: `${province.name} ${city} ${barangay}`,
      //   completeAddress: `${province.name} ${city} ${barangay}`,
      //   is_verified: true,
      //   referralCode,
      //   serviceCity: serviceCity,
      //   serviceProvince: serviceProvince.name,
      //   packageName: radioValue.packageName,
      //   packagePrice: radioValue.price,
      //   selectedSize,
      // };

      // console.log(">>>>>>> a:", a);

      // return;

      const formData = new FormData();

      formData.append("first_name", values.first_name);
      formData.append("last_name", values.last_name);
      formData.append("date_of_birth", values.dob);
      formData.append("contact_number", values.contact_number);
      formData.append("email", values.email);
      formData.append("user_name", values.username);
      formData.append("user_password", values.password);
      formData.append("role", "rider");
      formData.append("province", province.name);
      formData.append("city", city);
      formData.append("barangay", barangay);
      formData.append("address", `${province.name} ${city} ${barangay}`);
      formData.append(
        "completeAddress",
        `${province.name} ${city} ${barangay}`
      );
      formData.append("is_verified", true);
      formData.append("referralCode", referralCode);
      formData.append("serviceCity", serviceCity);
      formData.append("serviceProvince", serviceProvince.name);
      formData.append("platform", "rider");

      // formData.append("image", imageFileLogo);
      // formData.append("packageName", radioValue.packageName);
      // formData.append("packagePrice", radioValue.price);
      // formData.append("sleeveSize", selectedSize);

      const { data } = await axiosInstance().post("/api/rider", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (data) {
        // openNotification("SUCCESS", data.message);
        setLoading(false);
        // navigate("/success");

        navigate("/otp", {
          state: {
            otpSuccessLink: data.paymentLink,
            contact_number: values.contact_number,
            platform: "rider",
            token: data.token,
          },
        });

        // window.location.href = data.paymentLink;
      }
    } catch (error) {
      if (error.response.data.userAlreadyExist) {
        openNotification("ERROR", error.response.data.message);
      }

      if (error.response.data.invalidReferral) {
        openNotification("ERROR", error.response.data.message);
      }

      if (error.response.data.maximumRiders) {
        openNotification("INFO", error.response.data.message);
      }

      if (error.response.data.noPartnerInArea) {
        openNotification("INFO", error.response.data.message);
      }

      setLoading(false);
    }
  };

  // const uploadButtonLogo = (
  //   <button
  //     style={{
  //       border: 0,
  //       background: "none",
  //     }}
  //     type="button"
  //   >
  //     {loading ? <LoadingOutlined /> : <PlusOutlined />}
  //     <div
  //       style={{
  //         marginTop: 8,
  //       }}
  //     >
  //       Upload
  //     </div>
  //   </button>
  // );

  // const onChange = (e) => {
  //   setRadioValue(e.target.value);
  //   setSelectedSize("");
  // };

  // const handleChangeLogo = (info) => {
  //   setLoading(true);
  //   const file = info.file.originFileObj;

  //   console.log(">>>>> file:", file);

  //   setImageFileLogo(file);

  //   const localUrl = URL.createObjectURL(file);
  //   setImageUrlLogo(localUrl);
  //   setLoading(false);
  // };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          minHeight: "100vh",
          padding: "0 10px",
          boxSizing: "border-box",
          backgroundColor: "#00BF63",
        }}
      >
        <Card
          style={{
            width: "100%",
            maxWidth: 700,
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={icon} alt="" width={120} />
          </div>

          <Title level={2} style={{ margin: 0, textAlign: "center" }}>
            Application form
          </Title>
          {/* <Text type="secondary" style={{ fontSize: 16 }}>
            Enter your personal details
          </Text> */}
          <div style={{ marginTop: 20 }}>
            <Form
              layout="vertical"
              initialValues={{
                requiredMarkValue: true,
              }}
              requiredMark={true}
              onFinish={handleSubmit(onSubmit, onError)}
              encType="multipart/form-data"
            >
              <>
                <Divider orientation="left">Personal Information</Divider>
                <Row gutter={[16, 16]}>
                  <Col className="gutter-row" span={24} lg={12}>
                    {renderInput(
                      {
                        label: "First Name",
                        name: "first_name",
                        errors: errors,
                        required: "true",
                      },
                      control
                    )}
                  </Col>

                  <Col className="gutter-row" span={24} lg={12}>
                    {renderInput(
                      {
                        label: "Last Name",
                        name: "last_name",
                        errors: errors,
                        required: "true",
                      },
                      control
                    )}
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col className="gutter-row" span={24} lg={12}>
                    {renderInput(
                      {
                        type: "date",
                        label: "Date of birth",
                        name: "dob",
                        errors: errors,
                        required: "true",
                      },
                      control
                    )}
                  </Col>
                </Row>
                <Divider orientation="left">Contact Information</Divider>
                <Row gutter={[16, 16]}>
                  <Col className="gutter-row" span={24} lg={12}>
                    {renderInput(
                      {
                        label: "Contact Number",
                        name: "contact_number",
                        errors: errors,
                        required: "true",
                      },
                      control
                    )}
                  </Col>
                  <Col className="gutter-row" span={24} lg={12}>
                    {renderInput(
                      {
                        label: "Email",
                        name: "email",
                        errors: errors,
                        required: "true",
                      },
                      control
                    )}
                  </Col>
                </Row>
                <Divider orientation="left">Address Details</Divider>
                <Row gutter={[16, 16]}>
                  <Col className="gutter-row" span={24} lg={12}>
                    {renderInput(
                      {
                        label: "Province",
                        name: "province",
                        errors: errors,
                        required: "true",
                        type: "select",
                        options: uniqueOptions,
                        valueKey: "prov_code",
                        valueText: "name",
                        onChangeOutside: onChangeProvince,
                      },
                      control
                    )}
                  </Col>
                  <Col className="gutter-row" span={24} lg={12}>
                    {renderInput(
                      {
                        label: "City/Municipality",
                        name: "city",
                        errors: errors,
                        required: "true",
                        type: "select",
                        options: cities,
                        valueKey: "mun_code",
                        valueText: "name",
                        onChangeOutside: onChangeCity,
                      },
                      control
                    )}
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col className="gutter-row" span={24} lg={12}>
                    {renderInput(
                      {
                        label: "Barangay",
                        name: "barangay",
                        errors: errors,
                        required: "true",
                        type: "select",
                        options: barangays,
                        valueKey: "brgy_code",
                        valueText: "name",
                      },
                      control
                    )}
                  </Col>
                </Row>
                <Divider orientation="left">
                  Where do you want to deliver?
                </Divider>
                {isCityRiderMaxed && (
                  <>
                    <Alert
                      message="The rider count has reached its maximum capacity for this area."
                      type="error"
                      showIcon
                    />
                    <br />
                  </>
                )}
                <Row gutter={[16, 16]}>
                  <Col className="gutter-row" span={24} lg={12}>
                    {renderInput(
                      {
                        label: "Province",
                        name: "service_province",
                        errors: errors,
                        required: "true",
                        type: "select",
                        // options: serviceProvinces,
                        options: uniqueOptions,
                        valueKey: "prov_code",
                        valueText: "name",
                        onChangeOutside: onChangeServiceProvince,
                      },
                      control
                    )}
                  </Col>

                  <Col className="gutter-row" span={24} lg={12}>
                    {renderInput(
                      {
                        label: "City",
                        name: "service_city",
                        errors: errors,
                        required: "true",
                        type: "select",
                        options: serviceCities,
                        valueKey: "mun_code",
                        valueText: "name",
                        onChangeOutside: onChangeServiceCities,
                      },
                      control
                    )}
                  </Col>
                </Row>
                {/* test */}
                {/* <Divider orientation="left">
                  Delivery materials (required)
                </Divider> */}
                {/* <div style={{ marginBottom: 24, display: "flex" }}>
                  <Radio.Group onChange={onChange} value={radioValue}>
              

                    <Row gutter={[32]} style={{ display: "flex" }}>
                      {options.map((pk) => {
                        return (
                          <PackageCard
                            key={pk.id}
                            about={pk.about}
                            label={pk.label}
                            price={pk.value.price}
                            imgUrl={pk.imageUrl}
                            value={pk.value}
                            handleSizeChange={handleSizeChange}
                            selectedSize={selectedSize}
                            selectedPackage={radioValue?.packageName}
                            handlePackageSelect={handlePackageSelect}
                          />
                        );
                      })}
                    </Row>
                  </Radio.Group>
                </div> */}
                {/* 
                <Divider orientation="left">
                  Payment receipt of package availed
                </Divider>
                <Upload
                  accept="image/*"
                  name="image"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  onChange={handleChangeLogo}
                >
                  {imageUrlLogo ? (
                    <img
                      src={imageUrlLogo}
                      alt="avatar"
                      style={{
                        width: "100%",
                      }}
                    />
                  ) : (
                    uploadButtonLogo
                  )}
                </Upload> */}
                <Divider orientation="left">
                  Create username and password
                </Divider>
                <Row gutter={[16, 16]}>
                  <Col className="gutter-row" span={24} lg={12}>
                    {renderInput(
                      {
                        label: "Username",
                        name: "username",
                        errors: errors,
                        required: "true",
                      },
                      control
                    )}
                  </Col>

                  <Col className="gutter-row" span={24} lg={12}>
                    {renderInput(
                      {
                        label: "Password",
                        name: "password",
                        errors: errors,
                        required: "true",
                        type: "password",
                      },
                      control
                    )}
                  </Col>
                </Row>
                <Checkbox onChange={onCheck} style={{ marginBottom: 16 }}>
                  {`I have read and agree to the `}
                  <a
                    href="/Rider-Service-Agreement.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    terms of service
                  </a>
                </Checkbox>

                <div className="text-right mt-5" style={{ textAlign: "right" }}>
                  <ConfigProvider
                    theme={{
                      components: {
                        Button: {
                          defaultBg: "#00BF63",
                          defaultColor: "#FFF",
                        },
                      },
                    }}
                  >
                    <Button
                      block={true}
                      htmlType="submit"
                      type="default "
                      loading={loading}
                      disabled={isCityRiderMaxed || !acceptedTermsAndCondition}
                    >
                      Create Account
                    </Button>
                  </ConfigProvider>
                </div>
                {/*  */}
              </>
            </Form>
          </div>
        </Card>
      </div>
    </>
  );
}

export default RiderSignUp;
